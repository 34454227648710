import { environment } from "src/environments/environment";

export class ApiUrl {

  public static URL_BASE: string = environment.API_ENDPOINT

  // Customer
  public static LOGIN: string = this.URL_BASE + '/api/app/login';
  public static SIGNUP: string = this.URL_BASE + '/api/app/cadastrar/cliente';
  public static UPDATE_USER: string = this.URL_BASE + '/api/app/atualiza/cliente/:id';
  public static MAYBETOKENISINACTIVE: string = '/api/adm/produtos/1';

  // Address
  public static GET_ADDRESS: string = this.URL_BASE + '/api/app/enderecos/:id';
  public static CREATE_ADDRESS: string = this.URL_BASE + '/api/app/novo/endereco';
  public static UPDATE_ADDRESS: string = this.URL_BASE + '/api/app/atualiza/endereco/:id';
  public static DELETE_ADDRESS: string = this.URL_BASE + '/api/app/deletar/endereco/:id';

  public static GET_NEIGHBORHOODS: string = this.URL_BASE + '/api/adm/bairros/:id';


  // Segments
  public static ALLSEGMENTS: string = this.URL_BASE + '/api/adm/segmentos/:id';
  public static CREATE_SEGMENTO: string = this.URL_BASE + '/api/adm/segmento';
  public static GET_SEGMENTO: string = this.URL_BASE + '/api/adm/segmento/1/comercio/:id';
  public static UPDATE_SEGMENTO: string = this.URL_BASE + '/api/adm/segmento/1';
  public static DELETE_SEGMENTO: string = this.URL_BASE + '/api/adm/segmento/1/';

  // Products
  public static ALLPRODUCTS: string = this.URL_BASE + '/api/adm/produtos/:id';
  public static GET_PRODUCT: string = this.URL_BASE + '/api/adm/produto/:id'

  // Loyalty
  public static ALLLOYALTIES: string = this.URL_BASE + '/api/adm/fidelidades/:id';

  // Promotion
  public static PROMOTION: string = this.URL_BASE + '/api/adm/promocao/:id';

  // Discont coupons
  public static ALLPROMOTIONS: string = this.URL_BASE + '/api/adm/promocoes/:id';
  public static ALLCOUPONS: string = this.URL_BASE + '/api/adm/cupons/:id';

  // Pedido
  public static CRIAR_PEDIDO: string = this.URL_BASE + '/api/adm/novo/pedido';
  public static INSERIR_ITENS_PEDIDO: string = this.URL_BASE + '/api/adm/novo/item/pedido';

  // Order
  public static ORDER_STATUS: string = this.URL_BASE + '/api/adm/cliente/:id/pedido/:status';
  public static CONFIRM_DELIVERY: string = this.URL_BASE + '/api/adm/atualiza/pedido/:id';

  //Order history
  public static ORDER_HISTORY: string = this.URL_BASE + '/api/adm/loja/:id/pedidos/:status';

  //Store
  public static ALL_STORES: string = this.URL_BASE + '/api/comercios'
  public static STORE: string = this.URL_BASE + '/api/adm/:id'
  public static STORE_MESSAGE: string = this.URL_BASE + '/api/adm/mensagem/loja/:id'
  public static STORE_OPENING_HOURS: string = this.URL_BASE + '/api/adm/horarios/:id'
  public static STORE_PAYMENT_METHODS: string = this.URL_BASE + '/api/adm/meios_pagamento/:id'
  public static STORE_SOCIAL_MEDIA: string = this.URL_BASE + '/api/adm/redes_sociais/:id'
  public static STORE_OPEN_CLOSED: string = this.URL_BASE + '/api/adm/openAndClose/:id';

  //buscar comercial pelo nome
  public static BUSCAR_COMERCIAL: string = this.URL_BASE + '/api/adm/:tradeCode'
  public static BUSCAR_COMERCIAL_ID: string = this.URL_BASE + '/api/comercio/:id'
  public static INSERIR_ITENS_ADICIONAIS: string = this.URL_BASE + '/api/adm/novo/adicional/pedido';
  public static INSERIR_ITENS_OPCAO: string = this.URL_BASE + '/api/adm/novo/opcao/pedido';

  // Mercado Pago
  public static CREATE_PAYMENT_MERCADO_PAGO: string = this.URL_BASE + '/api/mp/checkout/:id';
  public static CREATE_ACCESS_TOKEN: string = this.URL_BASE + '/api/mp/token/:id';
  public static VERIFY_PAYMENT_MERCADO_PAGO: string = this.URL_BASE + '/api/mp/status/payment/:id';
  public static VERIFY_ACCEPT_PAYMENT_ONLINE: string = this.URL_BASE + '/api/mp/list/empresas';
  public static VERIFY_ACCEPT_PAYMENT_ONLINE_ID: string = this.URL_BASE + '/api/mp/list/empresas/:id';

  // Recovery password
  public static CREATE_TOKEN_RECOVERY: string = this.URL_BASE + '/api/password/create/token';
  public static VALIDATE_TOKEN_RECOVERY: string = this.URL_BASE + '/api/password/reset/verify';
  public static CREATE_NEW_PASSWORD: string = this.URL_BASE + '/api/password/reset/sms';
};
