import { Injectable } from '@angular/core';
import { ApiUrl } from './../../models/apiUrl';
import { ITradeCode } from './../../models/trade-code';
import { StringHelp } from './../../utils/string.help';
import { RequestService } from './../request/request.service';

@Injectable({
  providedIn: 'root'
})
export class TradeCodeService {

  constructor(
    private requestService: RequestService
  ) { }

  public async getTradeCode(tradeCode: string): Promise<ITradeCode | any > {
    return await this.requestService.post(StringHelp.replaceParameneterWithValue(ApiUrl.BUSCAR_COMERCIAL, [{tradeCode}]));
  }
  public async getID(id: string): Promise<ITradeCode | any > {
    return await this.requestService.post(StringHelp.replaceParameneterWithValue(ApiUrl.BUSCAR_COMERCIAL_ID, [{id}]));
  }

}
