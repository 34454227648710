import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggedService } from './../services/logged/logged.service';
import { TradeCodeService } from './../services/trade-code/trade-code.service';

@Injectable({
  providedIn: 'root'
})
export class TradeCodeGuard {

  constructor(
    private router: Router,
    private loggedService: LoggedService,
    private tradeCodeService: TradeCodeService,
  ) {

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>> {

    const commercial = this.loggedService.getComercial();

    if (!commercial) {
      const { trade_code } = route.params;


      if ([undefined, 'delivery'].includes(trade_code)) {
        return true;
      }

      try {
        const resultTradeCode = await this.tradeCodeService.getTradeCode(trade_code);
        localStorage.setItem('TradeCode', trade_code)
        this.loggedService.setComercial(resultTradeCode);
        this.router.navigate(['slider-inicial']);
      } catch (error) {
        return true;
      }


    }

    this.router.navigate(['slider-inicial']);

    return false;

  }

}
