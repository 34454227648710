import { patSymbosLiteralCharacters } from './../pattern/pattern.model';
class NumberUtil {

    public formatCurrency(value: string | number): string {

        if (typeof value === 'string'){
            value = this.convertStringToNumber(value);
        }

        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    public convertStringToNumber(value: string | number): number {

        if(typeof value === 'number') {
            return value;
        }

        const valueWithoutLiteralCharactersAndSymbols = value.replace(patSymbosLiteralCharacters, '');

        if (/\,/g.test(valueWithoutLiteralCharactersAndSymbols)) {
            const valueOnlyNumbersAndDot = valueWithoutLiteralCharactersAndSymbols.replace('.', '').replace(',', '.');
            return Number(valueOnlyNumbersAndDot);
        }

        const valueSplitForDot = valueWithoutLiteralCharactersAndSymbols.split('.');

        if (valueSplitForDot.length > 1) {
            let valueOnlyNumbersAndDot: string = '';
            for (let i = 0; i < valueSplitForDot.length; i++) {
                if (i === valueSplitForDot.length - 1) {
                    valueOnlyNumbersAndDot += '.';
                }
                valueOnlyNumbersAndDot += valueSplitForDot[i];
            }
            return Number(valueOnlyNumbersAndDot);
        }

        return Number(valueSplitForDot[0]);


    }

}

export const numberUtil = new NumberUtil();
