import { Component, ViewEncapsulation } from '@angular/core';
import { CapacitorGoogleMaps } from '@capacitor-community/capacitor-googlemaps-native';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { LoggedService } from './shared/services/logged/logged.service';
import { TradeCodeService } from './shared/services/trade-code/trade-code.service';
9
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  constructor(
    private tradeCodeService: TradeCodeService,
    private loggedService: LoggedService,
    private platform: Platform
  ) {
    const log: string = 'Desenvolvido com ❤️.';
    this.platform.backButton.subscribeWithPriority(10, () => {
      return
    });

    /*const isApp = true;
    const idComercioApp= '8'
    localStorage.setItem('id_Comercio',idComercioApp)
    localStorage.setItem('isApp', `${isApp}`);
    console.log(log)*/
    this.ionViewWillEnter()
  }
  async ionViewWillEnter() {
    window.addEventListener('beforeinstallprompt', (event) => {
      // Impedir que o prompt padrão de instalação seja mostrado
      event.preventDefault();
    
      // Exibir um prompt personalizado ou botão de instalação
      // Aqui você pode criar um botão ou outro elemento HTML para disparar o evento de instalação
      // Por exemplo:
      const installButton = document.createElement('button');
      installButton.textContent = 'Instalar App';
      installButton.addEventListener('click', () => {
        // Mostrar o prompt de instalação
        (event as any).prompt();
      });
    
      // Adicionar o botão ao DOM ou exibir seu próprio prompt personalizado
      // Aqui, por simplicidade, vou adicionar o botão ao body do documento
      document.body.appendChild(installButton);
    });
    
    
      const id = localStorage.getItem("id_Comercio")
      const resultTradeCode = await this.tradeCodeService.getTradeCode('bug');
      console.log('o resultado do trade', resultTradeCode)
      this.loggedService.setComercial(resultTradeCode);
      localStorage.setItem('TradeCode', resultTradeCode['nome']);
      this.mouthBusinees()
  }
  ngOnInit() {

  }
  mouthBusinees(){
    console.log(this.loggedService.getComercial()?.id)
    if (this.loggedService.getComercial()?.id !== undefined) {
      console.log(this.loggedService.getComercial()?.id)
      let cor = '#' + this.loggedService.getComercial().corTema.replace('#', '');
      console.log(cor);
      this.setColorVariable('--app-color-primary', cor);
    }
  }

  private async inicializa() {
    CapacitorGoogleMaps.initialize({
      key: environment.mapsKey,
    });
  }

  private setColorVariable(variable: string, color: string): void {
    document.documentElement.style.setProperty(variable, color);
  }
}
