import { Injectable } from '@angular/core';
import { ICustomer } from 'src/app/shared/models/ICustomer';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { IPremio, IPremioCart, IProdutoCart } from '../../models/IProduct';
import { KeyLocalStorage } from './../../models/key-localStorage';
import { ITradeCode } from './../../models/trade-code';
import { SecretUtil } from './../../utils/secret-util';

@Injectable({
  providedIn: 'root',
})
export class LoggedService {
  constructor(private localStorageService: LocalStorageService) { }

  public clear(): Function {
    this.localStorageService.remove(KeyLocalStorage.USER);
    this.localStorageService.remove(KeyLocalStorage.USER_TOKEN);
    this.localStorageService.remove(KeyLocalStorage.PRODUCTS_CART);
    return this.clearForce;
  }

  public clearForce(): boolean {
    return this.localStorageService.remove(KeyLocalStorage.COMMERCIAL);
  }

  public getProducts(): IProdutoCart[] {
    const produtosEcrypt = this.localStorageService.get(
      KeyLocalStorage.PRODUCTS_CART
    );

    if (produtosEcrypt) {
      return JSON.parse(SecretUtil.decrypt(produtosEcrypt));
    }

    return [];
  }

  public addProduto(produto: IProdutoCart): void {
    const produtos = this.getProducts();

    produtos.push(produto);

    this.localStorageService.set(
      KeyLocalStorage.PRODUCTS_CART,
      SecretUtil.encrypt(JSON.stringify(produtos))
    );
  }

  public setProduto(produto: IProdutoCart, index: any): void {
    const produtos = this.getProducts();
    produtos[index] = produto
    this.localStorageService.set(
      KeyLocalStorage.PRODUCTS_CART,
      SecretUtil.encrypt(JSON.stringify(produtos))
    );
  }

  public removeProduto(produto: IProdutoCart): boolean {
    const produtos = this.getProducts();
    const newListProducts = produtos.filter(
      (produtoCart) => produtoCart.tempId !== produto.tempId
    );
    if (newListProducts) {
      this.localStorageService.set(
        KeyLocalStorage.PRODUCTS_CART,
        SecretUtil.encrypt(JSON.stringify(newListProducts))
      );
      return true;
    }
    return false;
  }

  public clearProdutos(): boolean {
    return this.localStorageService.remove(KeyLocalStorage.PRODUCTS_CART);
  }

  public getComercial(): ITradeCode {
    return JSON.parse(
      SecretUtil.decrypt(
        this.localStorageService.get(KeyLocalStorage.COMMERCIAL)
      )
    );
  }

  public setComercial(resultTradeCode: ITradeCode): boolean {
    return this.localStorageService.set(
      KeyLocalStorage.COMMERCIAL,
      SecretUtil.encrypt(JSON.stringify(resultTradeCode))
    );
  }

  public getUser(): ICustomer {
    return JSON.parse(
      SecretUtil.decrypt(this.localStorageService.get(KeyLocalStorage.USER))
    );
  }

  public setUser(user: ICustomer): boolean {
    return this.localStorageService.set(
      KeyLocalStorage.USER,
      SecretUtil.encrypt(JSON.stringify(user))
    );
  }

  public setToken(token: string): boolean {
    return this.localStorageService.set(KeyLocalStorage.USER_TOKEN, token);
  }

  public getToken(): string {
    return this.localStorageService.get(KeyLocalStorage.USER_TOKEN);
  }

  public setPremios(argPremios: IPremio): boolean {
    const premios = this.getPremios();
    argPremios.key = Math.random().toString(36).substring(2, 15);
    premios.push(argPremios);
    return this.localStorageService.set(
      KeyLocalStorage.PREMIOS,
      SecretUtil.encrypt(JSON.stringify(premios))
    );
  }

  public getPremios(): any[] {
    const premiosEcrypt = this.localStorageService.get(KeyLocalStorage.PREMIOS);

    if (premiosEcrypt) {
      return JSON.parse(SecretUtil.decrypt(premiosEcrypt));
    }

    return [];
  }

  public getPremiosCart(): IPremioCart[] {
    const premiosCart = this.localStorageService.get(
      KeyLocalStorage.PREMIOS_CART
    );

    if (premiosCart) {
      return JSON.parse(SecretUtil.decrypt(premiosCart));
    }

    return [];
  }

  public getPremiosNoCart(): IPremio[] {
    const premios = this.getPremios();
    const premiosCart = this.getPremiosCart();

    let premiosNoCart: IPremio[] = [];

    premios.forEach((premio) => {
      const premioCart = premiosCart.find(
        (premioCart) => premioCart.premio.key === premio.key
      );
      if (!premioCart) {
        premiosNoCart.push(premio);
      }
    });

    return premiosNoCart;
  }

  public setPremioCart(argPremios: IPremioCart): boolean {
    const premiosCart = this.getPremiosCart();
    premiosCart.push(argPremios);
    return this.localStorageService.set(
      KeyLocalStorage.PREMIOS_CART,
      SecretUtil.encrypt(JSON.stringify(premiosCart))
    );
  }

  public removePremioCart(argPremios: IPremioCart): boolean {
    const premiosCart = this.getPremiosCart();
    const newListProducts = premiosCart.filter(
      (premioCart) => premioCart.premio.key !== argPremios.premio.key
    );
    if (newListProducts) {
      this.localStorageService.set(
        KeyLocalStorage.PREMIOS_CART,
        SecretUtil.encrypt(JSON.stringify(newListProducts))
      );
      return true;
    }
    return false;
  }

  public removePremio(argPremio: IPremio): boolean {
    const premios = this.getPremios();
    const newListProducts = premios.filter(
      (premio) => premio.key !== argPremio.key
    );
    if (newListProducts) {
      this.localStorageService.set(
        KeyLocalStorage.PREMIOS,
        SecretUtil.encrypt(JSON.stringify(newListProducts))
      );
      return true;
    }
    return false;
  }

  public clearPremiosCart(): boolean {
    const premioCart = this.getPremiosCart();

    premioCart.forEach((premio) => {
      this.removePremio(premio.premio);
    });

    return this.localStorageService.remove(KeyLocalStorage.PREMIOS_CART);
  }
}
