export enum KeyLocalStorage {
    USER_TOKEN = 'token',
    PRODUCTS_CART = 'products_cart',
    USER = 'user',    
    
    STORE_STATUS = 'ativa',
    STORE_ESTADO = 'estado',
    COMMERCIAL = 'comercial',
    PREMIOS = 'premios',
    PREMIOS_CART = 'premios_cart',
}