import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefone'
})
export class TelefonePipe implements PipeTransform {
  transform(value: string) {
    if (value !== null) {
      const tamanho = value.length;
      value = value.toString();
      if (tamanho === 11) {
        return '(' + value.substr(0, 2) + ') ' + value.substr(2, 5) + '-' + value.substr(7, 12);
      } else {
        if (tamanho === 10) {
          return '(' + value.substr(0, 2) + ') ' + value.substr(2, 4) + '-' + value.substr(6, 12);
        } else {
          return value;
        }
      }
    } else {
      return value;
    }
  }
}
