import { Pipe, PipeTransform } from '@angular/core';
import { numberUtil } from '../utils/number.util';

@Pipe({
  name: 'CurrencyReal'
})
export class CurrencyRealPipe implements PipeTransform {
  transform(value: string | number | null | undefined): string {
    if (!value) {
      return '';
    }
    return numberUtil.formatCurrency(value);
  }
}
