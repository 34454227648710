import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ToastService } from './../components/toaster/toast.service';
import { UserService } from './user/user.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  providers: [
    ToastService,
    UserService
  ],
})
export class ServicesModule {}
