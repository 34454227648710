import { Injectable } from '@angular/core';
import { ApiUrl } from '../../models/apiUrl';
import { ICustomerUpdateReponse } from './../../models/ICustomer';
import { StringHelp } from './../../utils/string.help';
import { LoggedService } from './../logged/logged.service';
import { RequestService } from './../request/request.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private requestService: RequestService,
    private loggedService: LoggedService
  ) {}

  public async updateUser(formData: FormData): Promise<ICustomerUpdateReponse> {
    const url =
      StringHelp.replaceParameneterWithValue(ApiUrl.UPDATE_USER, [
        { id: this.loggedService.getUser().id },
      ]);

    return await this.requestService
      .setHeaderToken()      
      .post(url, formData)
  }
}
