import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TradeCodeGuard } from './shared/guard/trade-code.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./core/login-layout/login-layout.module').then(m => m.LoginLayoutModule)
  },
  {
    path: 'logged',
    loadChildren: () => import('./core/logged-layout/logged-layout.module').then(m => m.LoggedLayoutModule)
  },
  {
    path: 'slider-inicial',
    loadChildren:() => import('./pages/slider-inicial/slider-inicial.module').then(m => m.SliderInicialPageModule) ,
  },
  {
    path: ':trade_code',
    redirectTo: 'trade-code/bug',
  },
  {
    path: 'trade-code/:trade_code',
    pathMatch: 'full',
    loadChildren: () => import('./pages/trade-code/trade-code.module').then(m => m.TradeCodeModule),
    canActivate: [TradeCodeGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'trade-code/bug' /// se for app modificar o nome aqui da loja
  },
  {
    path: 'logged/blocked-user',
    loadChildren: () => import('./pages/blocked-user/blocked-user.module').then( m => m.BlockedUserPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
