import { Pipe, PipeTransform } from '@angular/core';
import { convertToPorcentagem } from '../utils/porcentagem.util';

@Pipe({
  name: 'porcentagem'
})
export class PorcentagemPipe implements PipeTransform {
  transform(value: string | number): number {
    return convertToPorcentagem(value);
}
}
