import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrencyRealPipe } from './convertValueReal.pipe';
import { PorcentagemPipe } from './porcentagem.pipe';
import { TelefonePipe } from './telefone.pipe';


@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    TelefonePipe,
    CurrencyRealPipe,
    PorcentagemPipe,
  ],
  declarations: [
    TelefonePipe,
    CurrencyRealPipe,
    PorcentagemPipe,
  ],
  providers: [],
})
export class PipeModule { }
