// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mapsKey: 'AIzaSyAJel0aGjQmNX1b5U4CVK7E4uXCXkZMJnE',
  geoLocationKey: 'AIzaSyCctXHk8gLWZ5KeYuC0yYGcvTejyR3mjLQ',
  distanceMatrixService: 'AIzaSyDk02h5Of3y2SKdun7CoJQBLxppEeJpof4',

  API_ENDPOINT: 'https://api-delivery.fly.dev',
  //API_URL_IMAGE: 'https://edra-app-images.s3.sa-east-1.amazonaws.com/',
  //API_URL_IMAGE: '../assets/edralivery-img/',
  API_URL_IMAGE: 'https://edrafox.com.br/assets/edralivery-img/',
  API_GOOGLE_MAPS: 'https://maps.googleapis.com/maps/api',
  API_GOOGLE_MAPS_GET_ADDRESS: 'https://maps.googleapis.com/maps/api/geocode/json',
  API_GOOGLE_MAPS_DIRECTIONS:
    'http://maps.googleapis.com/maps/api/distancematrix/json',

  API_URL_FACEBOOK: 'https://www.facebook.com/',
  API_URL_INSTAGRAM: 'https://www.instagram.com/',
  API_URL_WHATSAPP: 'https://wa.me/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
